/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import cn from 'classnames'

import * as st from '~/assets/styl/Politicas.module.styl'

export default function TermosDeUsoContent() {
  return (
    <section className={cn(st.politicsContainer, 'page-width')}>
      <h2>Política de privacidade</h2>
      <p>1. Objetivo</p>
      <p>
        As Empresas Dimas estão engajadas na conscientização dos usuários de seu
        site em relação aos dados pessoais eventualmente tratados.
      </p>
      <p>
        Comprometido em respeitar a sua privacidade e prezar pela transparência,
        elaboramos a presente política de privacidade, com o objetivo de
        proporcionar um claro entendimento sobre como é realizado o tratamento
        dos seus dados pessoais em nosso site.
      </p>
      <p>
        Destaca-se que o presente instrumento informa sobre como é realizado o
        tratamento de dados pessoais coletados exclusivamente no site do Dimas
        Construções. As demais hipóteses de tratamento de dados de
        colaboradores, fornecedores, candidatos a vagas de emprego, dentre
        outros, estão na Política de Privacidade interna das Empresas Dimas, a
        qual pode ser solicitada pelos titulares através do e-mail{' '}
        <a href="mailto:privacidade@dimas.com.br">privacidade@dimas.com.br</a>.
      </p>
      <p>
        Os dados pessoais serão tratados apenas enquanto forem necessários e
        adequados para as finalidades que embasam a coleta, sempre atentos às
        bases legais do tratamento.
      </p>
      <p>
        As Empresas Dimas requerem a todos os usuários de seu site que façam a
        leitura atenta deste documento antes de submeter seus dados nos
        formulários de cadastro. Seu cadastro significa que você compreendeu
        como coletamos e tratamos seus dados pessoais.
      </p>
      <p>
        Para mais informações acerca do tratamento de seus dados pessoais, entre
        em contato através do e-mail{' '}
        <a href="mailto:privacidade@dimas.com.br">privacidade@dimas.com.br</a>.
      </p>
      <p>2. Definições</p>
      <ul>
        <li>
          titular: pessoa natural a quem se referem os dados pessoais que são
          objeto de tratamento;
        </li>
        <li>
          dado pessoal: informação relacionada a pessoa natural identificada ou
          identificável, como por exemplo nome, e-mail, telefone, dentre outros;
        </li>
        <li>
          dado pessoal sensível: dado pessoal sobre origem racial ou étnica,
          convicção religiosa, opinião política, filiação a sindicato ou a
          organização de caráter religioso, filosófico ou político, dado
          referente à saúde ou à vida sexual, dado genético ou biométrico,
          quando vinculado a uma pessoa natural;
        </li>
        <li>
          tratamento: toda operação realizada com dados pessoais, como as que se
          referem a coleta, produção, recepção, classificação, utilização,
          acesso, reprodução, transmissão, distribuição, processamento,
          arquivamento, armazenamento, eliminação, avaliação ou controle da
          informação, modificação, comunicação, transferência, difusão ou
          extração;
        </li>
        <li>
          controlador: pessoa natural ou jurídica, de direito público ou
          privado, a quem competem as decisões referentes ao tratamento de dados
          pessoais;
        </li>
        <li>
          encarregado: pessoa indicada pelo controlador e operador para atuar
          como canal de comunicação entre o controlador, os titulares dos dados
          e a Autoridade Nacional de Proteção de Dados (ANPD);
        </li>
        <li>
          operador: pessoa natural ou jurídica, de direito público ou privado,
          que realiza o tratamento de dados pessoais em nome do controlador;
        </li>
        <li>
          transferência internacional de dados: transferência de dados pessoais
          para país estrangeiro ou organismo internacional do qual o país seja
          membro;
        </li>
        <li>
          Autoridade Nacional de Proteção de Dados (ANPD): órgão da
          administração pública federal, integrante da Presidência da República,
          que possui, dentre outras funções previstas pela Lei n. 13.709/2018, a
          de zelar pela proteção dos dados pessoais, elaborar diretrizes para a
          Política Nacional de Proteção de Dados Pessoais e da Privacidade,
          fiscalizar e aplicar sanções em caso de tratamento de dados realizado
          em descumprimento à legislação, mediante processo administrativo que
          assegure o contraditório, a ampla defesa e o direito de recurso e
          promover na população o conhecimento das normas e das políticas
          públicas sobre proteção de dados pessoais e das medidas de segurança;
        </li>
        <li>
          uso compartilhado de dados: comunicação, difusão, transferência
          internacional, interconexão de dados pessoais ou tratamento
          compartilhado de bancos de dados pessoais por órgãos e entidades
          públicos no cumprimento de suas competências legais, ou entre esses e
          entes privados, reciprocamente, com autorização específica, para uma
          ou mais modalidades de tratamento permitidas por esses entes públicos,
          ou entre entes privados.
        </li>
      </ul>
      <p>
        3. rincípios aplicáveis ao tratamento de dados realizado pelas Empresas
        Dimas
      </p>
      <p>
        As Empresas Dimas, no papel de Controlador, asseguram o integral
        cumprimento do disposto pelo art. 6º, da Lei Geral de Proteção de Dados,
        o qual estipula os princípios que regem as operações de tratamento de
        dados pessoais, quais sejam:
      </p>
      <ul>
        <li>
          finalidade: realização do tratamento para propósitos legítimos,
          específicos, explícitos e informados ao titular, sem possibilidade de
          tratamento posterior de forma incompatível com essas finalidades;{' '}
        </li>
        <li>
          adequação: compatibilidade do tratamento com as finalidades informadas
          ao titular, de acordo com o contexto do tratamento;
        </li>
        <li>
          necessidade: limitação do tratamento ao mínimo necessário para a
          realização de suas finalidades, com abrangência dos dados pertinentes,
          proporcionais e não excessivos em relação às finalidades do tratamento
          de dados;{' '}
        </li>
        <li>
          livre acesso: garantia, aos titulares, de consulta facilitada e
          gratuita sobre a forma e a duração do tratamento, bem como sobre a
          integralidade de seus dados pessoais;
        </li>
        <li>
          qualidade dos dados: garantia, aos titulares, de exatidão, clareza,
          relevância e atualização dos dados, de acordo com a necessidade e para
          o cumprimento da finalidade de seu tratamento;{' '}
        </li>
        <li>
          transparência: garantia, aos titulares, de informações claras,
          precisas e facilmente acessíveis sobre a realização do tratamento e os
          respectivos agentes de tratamento, observados os segredos comercial e
          industrial;
        </li>
        <li>
          segurança: utilização de medidas técnicas e administrativas aptas a
          proteger os dados pessoais de acessos não autorizados e de situações
          acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
          difusão;
        </li>
        <li>
          prevenção: adoção de medidas para prevenir a ocorrência de danos em
          virtude do tratamento de dados pessoais;
        </li>
        <li>
          não discriminação: impossibilidade de realização do tratamento para
          fins discriminatórios ilícitos ou abusivos;
        </li>
        <li>
          responsabilização e prestação de contas: demonstração, pelo agente, da
          adoção de medidas eficazes e capazes de comprovar a observância e o
          cumprimento das normas de proteção de dados pessoais e, inclusive, da
          eficácia dessas medidas.
        </li>
      </ul>
      <p>4. Hipóteses em que é permitido o tratamento de dados</p>
      <p>
        A LGPD prevê, em seu art. 7º, as hipóteses em que o tratamento de dados
        pessoais é permitido. Estas hipóteses são denominadas "bases legais" e
        elas podem ser utilizadas tanto individualmente, quanto de maneira
        conjunta, para respaldar o tratamento de dados. Importante esclarecer
        que as bases legais para o tratamento de dados são:
      </p>
      <ul>
        <li>o consentimento;</li>
        <li>
          o cumprimento de obrigação legal ou regulatória pelo controlador;
        </li>
        <li>
          o tratamento e uso compartilhado de dados realizado pela administração
          pública necessários à execução de políticas públicas previstas em leis
          e regulamentos ou respaldadas em contratos, convênios ou instrumentos
          congêneres;
        </li>
        <li>
          a realização de estudos por órgão de pesquisa, garantida, sempre que
          possível, a anonimização dos dados pessoais;
        </li>
        <li>
          a execução de contrato ou de procedimentos preliminares relacionados a
          contrato do qual seja parte o titular, a pedido do titular dos dados;
        </li>
        <li>
          o exercício regular de direitos em processo judicial, administrativo
          ou arbitral;
        </li>
        <li>
          a proteção da vida ou da incolumidade física do titular ou de
          terceiro.
        </li>
      </ul>
      <p>5. Dados coletados, a forma e a finalidade da coleta</p>
      <p>5.1 Dados pessoais</p>
      <p>
        As Empresas Dimas realizarão o tratamento dos dados inseridos no site{' '}
        <a
          href="https://www.dimasconstrucoes.com.br"
          target="_blank"
          rel="noreferrer"
        >
          https://www.dimasconstrucoes.com.br
        </a>
        . Estes dados serão fornecidos diretamente por você através dos
        formulários "CONTATO &gt; Atendimento", "Login", "Empreendimento &gt;
        Contato", "Empreendimentos &gt; Visite o site &gt; Cadastre-se para
        conhecer primeiro" e "Enviar mensagem &gt; Ouvidoria":
      </p>
      <ul>
        <li>Nome;</li>
        <li>E-mail;</li>
        <li>CPF;</li>
        <li>Data de nascimento;</li>
        <li>Telefone;</li>
      </ul>
      <p>Estes dados serão coletados para cumprir as seguintes finalidades:</p>
      <ul>
        <li>Contatar o usuário do site por e-mail e/ou WhatsApp;</li>
        <li>
          Realizar o cadastro do usuário nas campanhas de e-mail marketing para
          compartilhamento de novos lançamentos;
        </li>
        <li>Tirar dúvidas de interessados acerca dos empreendimentos;</li>
        <li>Efetuar o cadastro e login no site;</li>
        <li>Direcionamento de publicidade.</li>
      </ul>
      <p>6. Período de armazenamento dos dados pessoais</p>
      <p>
        Os dados coletados no site do Dimas Construções serão armazenados: a)
        pelo tempo exigido por lei; b) até o término do tratamento dos dados
        pessoais, ou seja, quando do alcance da finalidade ou quando os dados
        deixarem de ser necessários ou pertinentes ao alcance da finalidade ou
        quando o titular estiver em seu direito de solicitar o término do
        tratamento e a exclusão de seus dados ou quando houver determinação
        legal nesse sentido; c) pelo tempo necessário a preservar o legítimo
        interesse das Empresas Dimas, conforme o caso; d) pelo tempo necessário
        para resguardar o exercício regular de direitos das Empresas Dimas.
      </p>
      <p>7. Controlador dos dados pessoais</p>
      <p>
        As Empresas Dimas serão o controlador de seus dados pessoais. Para a
        finalidade da legislação aplicável, controlador é a quem compete as
        decisões relativas ao tratamento de dados pessoais.
      </p>
      <p>8. Encarregado dos dados pessoais</p>
      <p>
        As Empresas Dimas nomeiam como Encarregado o Comitê de Privacidade das
        Empresas Dimas que poderão ser contato através do endereço de e-mail{' '}
        <a href="mailto:privacidade@dimas.com.br">privacidade@dimas.com.br</a>.
      </p>
      <p>9. Compartilhamento dos seus dados pessoais</p>
      <p>
        As Empresas Dimas compartilham com a agência TikiWeb, RD Station e
        Neurologic para as seguintes finalidades:
      </p>
      <ul>
        <li>Desenvolvimento e codificação do site;</li>
        <li>
          Adoção de eventuais providências essenciais ao bom funcionamento das
          funcionalidades oferecidas no site;
        </li>
        <li>
          Disponibilizar a ferramenta de ChatBot para o envio de proposta de
          financiamento de veículo;{' '}
        </li>
        <li>
          Gerenciar os dados dos clientes interessados em nossos serviços e
          produtos para, eventualmente, contatá-los.
        </li>
      </ul>
      <p>
        Ademais, no caso de candidatos a oportunidades de emprego, poderemos
        compartilhar seus dados com a plataforma Gupy, para a seleção de
        candidatos e realização de processo seletivo.
      </p>
      <p>10. Direitos em relação aos seus dados pessoais</p>
      <p>
        Você possui diversos direitos em relação aos seus dados pessoais, os
        quais as Empresas Dimas se comprometem em assegurar, dentre eles:
      </p>
      <ul>
        <li>a confirmação da existência de tratamento;</li>
        <li>o acesso aos dados;</li>
        <li>a correção de dados incompletos, inexatos ou desatualizados;</li>
        <li>
          a anonimização, bloqueio ou eliminação de dados desnecessários,
          excessivos ou tratados em desconformidade;
        </li>
        <li>
          a portabilidade a outro fornecedor de serviço ou produto, mediante
          requisição expressa;
        </li>
        <li>
          a informação das entidades públicas e privadas com as quais o
          controlador realizou o uso compartilhado de dados.
        </li>
      </ul>
      <p>11. Armazenamento e segurança</p>
      <p>
        As Empresas Dimas estão engajadas com a segurança dos seus dados
        pessoais. O Controlador adota todas as precauções para manter essa
        proteção, empregando sistemas de segurança e procedimentos adotados pelo
        mercado para proteger os seus dados.
      </p>
      <p>
        Cumpre ressaltar, ainda, que o controlador comunicará à autoridade
        nacional e ao titular a ocorrência de incidente de segurança que possa
        acarretar risco ou dano relevante aos titulares.
      </p>
      <p>12. Dúvidas | Contato</p>
      <p>
        Caso você entenda que seus dados foram utilizados de maneira
        incompatível com esta Política ou com as suas escolhas enquanto titular,
        ou, ainda, se você tiver outras dúvidas, comentários ou sugestões, entre
        em contato conosco através do endereço de e-mail{' '}
        <a href="mailto:privacidade@dimas.com.br">privacidade@dimas.com.br</a>.
      </p>
      <p>13. Alterações</p>
      <p>
        As Empresas Dimas reservam o direito de alterar esta Política de
        Privacidade a qualquer momento, mediante publicação da versão atualizada
        nesta página, a fim de garantir que esta reflita o real tratamento de
        dados pessoais realizado por nós.
      </p>
      <p>
        A presente Política de Privacidade entra em vigor em 22 de setembro de
        2021.
      </p>
    </section>
  )
}
