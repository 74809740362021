// extracted by mini-css-extract-plugin
export var gatsbyFocusWrapper = "Politicas-module--gatsby-focus-wrapper--DFz1p";
export var hideOnDesktop = "Politicas-module--hide-on-desktop--DyIwa";
export var hideOnMobile = "Politicas-module--hide-on-mobile--ypmMC";
export var iconCar = "Politicas-module--icon-car--sXGDD";
export var iconCars = "Politicas-module--icon-cars--G4nv+";
export var iconClock = "Politicas-module--icon-clock--76LFc";
export var iconEletricCar = "Politicas-module--icon-eletric-car--C7dCK";
export var iconFacebook = "Politicas-module--icon-facebook--hqHKD";
export var iconGear = "Politicas-module--icon-gear--QzU-B";
export var iconInstagram = "Politicas-module--icon-instagram--0ckEV";
export var iconKm = "Politicas-module--icon-km--RhcF0";
export var iconPhone = "Politicas-module--icon-phone--Q9+LJ";
export var iconRight = "Politicas-module--icon-right--23meZ";
export var iconWhatsapp = "Politicas-module--icon-whatsapp--CJlnx";
export var pageWidth = "Politicas-module--page-width--VC65S";
export var path1 = "Politicas-module--path1--0dKUZ";
export var path2 = "Politicas-module--path2--t7xUg";
export var path3 = "Politicas-module--path3--Y-1Ce";
export var politicsContainer = "Politicas-module--politics-container--YQllJ";
export var view = "Politicas-module--view--8uvm6";
export var views = "Politicas-module--views--l+oVC";